<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      okText="Save"
      title="New Supply"
      :visible="visible"
      @cancel="$emit('close')"
      @ok="onConfirm"
    >
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="Place of Supply" :colon="false" required>
          <a-select
            v-decorator="['country_code', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Place of supply is required.' }],
              }]
            }]"
            name="country_code"
            placeholder="Select a country"
            showSearch
            :filterOption="filterOption"
            @change="onCountryChange"
          >
            <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Customer Name" :colon="false">
          <a-input
            name="name"
            type="text"
            v-decorator="['name']"
          />
        </a-form-item>
        <a-form-item label="Item Description" :colon="false">
          <a-input
            name="description"
            type="text"
            v-decorator="['description']"
          />
        </a-form-item>
        <h2 class="h4">Pricing</h2>
        <a-form-item label="Amount" :colon="false" required>
          <a-input
            name="amount"
            placeholder="0"
            type="number"
            v-decorator="['amount', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Amount is required.' }],
              }]
            }]"
          >
            <a-select
              :filterOption="filterOption"
              name="currency"
              showSearch
              slot="addonAfter"
              style="width: 150px;"
              v-decorator="['currency']"
            >
              <a-select-option v-for="name in currencies" :key="name" :value="name">
                {{ name }}
              </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
        <div class="row">
          <div class="col-6">
            <a-form-item label="VAT Rate (%)" :colon="false">
              <a-select
                :disabled="!rate"
                name="vat.rate"
                v-decorator="['vat.rate']"
              >
                <a-select-opt-group label="Standard Rate">
                  <a-select-option :value="rate && rate.standard_rate">
                    {{ rate && rate.standard_rate }}%
                  </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group v-if="rate && rate.reduced_rates.length" label="Reduced Rates">
                  <a-select-option v-for="reducedRate in rate.reduced_rates" :key="reducedRate" :value="reducedRate">
                    {{ reducedRate }}%
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-item>
          </div>
          <div class="col-6">
            <a-form-item label="VAT Pricing" :colon="false">
              <a-radio-group
                name="vat.inclusive"
                v-decorator="['vat.inclusive', {
                  initialValue: false,
                }]"
              >
                <a-radio :value="true">
                  incl.
                </a-radio>
                <a-radio :value="false">
                  excl.
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
        </div>
        <h2 class="h4">Invoice</h2>
        <div class="row">
          <div class="col-6">
            <a-form-item label="Invoice Number" :colon="false" required>
              <a-input
                name="invoice_number"
                type="text"
                v-decorator="['invoice_number', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Invoice number is required.' }],
                  }]
                }]"
              />
            </a-form-item>
          </div>
          <div class="col-6">
            <a-form-item label="Issued Timestamp" :colon="false" required>
              <a-date-picker
                placeholder="Select date and time"
                show-time
                style="width: 100%;"
                v-decorator="['issued', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Issued date is required.' }],
                  }]
                }]"
              />
            </a-form-item>
          </div>
        </div>
        <a-collapse :bordered="false" style="background-color: transparent;">
          <a-collapse-panel key="1" header="More Details" style="border-bottom: none;">
            <a-form-item label="Notes" :colon="false">
              <a-input
                name="notes"
                type="text"
                v-decorator="['notes']"
              />
            </a-form-item>
            <a-form-item label="Amount Refunded" :colon="false">
              <a-input
                name="amount_refunded"
                placeholder="0"
                type="number"
                v-decorator="['amount_refunded']"
              />
            </a-form-item>
          </a-collapse-panel>
        </a-collapse>
      </a-form>
      <small>
        <p>Each new supply will consume <strong>1</strong> hit. You can edit a supply afterwards as many times as you want.</p>
      </small>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      rate: null,
      currencies: ['AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CNY', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HRK', 'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JPY', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'RUB', 'SEK', 'SGD', 'THB', 'TRY', 'USD', 'ZAR'],
    }
  },

  created() {
    this.form = this.$form.createForm(this, {
      mapPropsToFields: () => {
        return {
          currency: this.$form.createFormField({ value: 'USD' }),
        }
      },
    })
  },

  methods: {
    async onCountryChange(countryCode) {
      try {
        this.rate = null

        var { data } = await this.$httpd.get('/rates', {
          params: { country_code: countryCode }
        })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      if (data.rates_count) {
        this.rate = data.rates[0]
      }
    },
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$http.post(`/supplies`, {
            ...values,
            amount: values.amount * 100,
            amount_refunded: values.amount_refunded * 100,
          }, {
            headers: { 'x-api-key': this.$store.getters.secretKey },
          })
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message || this.$store.state.errorMessages[err.response.data.code])
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.$router.push({ name: 'supplies-id', params: { id: data.id }})
        this.$message.success('Supply has been created.')
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
