<template>
  <div>
    <SupplyHits
      class="mb-3"
    />
    <div class="card mb-3">
      <div class="card-body">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <a-input
                v-model="filters.invoice_number"
                allowClear
                placeholder="Invoice Number"
                @change="onQueryChange"
              />
            </div>
            <div class="col-8 col-lg-6">
              <a-range-picker
                class="w-100"
                :defaultValue="defaultPickerValues"
                :placeholder="['Issued since', 'Issued until']"
                @change="onDateChange"
              />
            </div>
            <div class="col-4 col-lg-2">
              <a-button htmlType="submit" block>
                Filter
              </a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-6">
            <strong>Supplies <span v-if="suppliesCount !== null">({{ suppliesCount }})</span></strong>
          </div>
          <div class="col-6 text-right">
            <ul class="list-inline mb-0">
              <li class="list-inline-item mr-3">
                <a href="https://vatstack.com/docs/supplies" target="_blank">API Reference <i class="fal fa-external-link ml-1"></i></a>
              </li>
              <li class="list-inline-item">
                <SupplyCreate :visible="visible" @close="visible = false">
                  <a-button type="primary" @click="visible = true">
                    <i class="fal fa-plus mr-2"></i> New Supply
                  </a-button>
                </SupplyCreate>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <BaseSpinner :loading="loading">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th>Name</th>
              <th>Total Amount</th>
              <th>VAT (%)</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="supply in supplies"
              :key="supply.id"
            >
              <td>
                <router-link :to="{ name: 'supplies-id', params: { id: supply.id }}" class="text-dark">
                  <a-tooltip v-if="supply.evidence_status === 'insufficient'" title="Insufficient Evidence" placement="left">
                    <i class="fad fa-exclamation-circle text-warning mr-1"></i> {{ supply.invoice_number }}
                  </a-tooltip>
                  <a-tooltip v-else title="Sufficient Evidence" placement="left">
                    <i class="fad fa-check-circle text-success mr-1"></i> {{ supply.invoice_number }}
                  </a-tooltip>
                </router-link>
              </td>
              <td :class="{ 'text-danger': supply.amount_total < 0 }">
                {{ convertPrice(supply.amount_total) }} {{ supply.currency }}
              </td>
              <td :class="{ 'text-danger': supply.amount_total < 0 }">
                {{ convertPrice(supply.vat.amount) }}
                {{ supply.vat.currency }}
                ({{ supply.vat.rate }}%)
              </td>
              <td>
                {{ convertDateTime(supply.created) }}
              </td>
            </tr>
            <tr v-if="!suppliesCount">
              <td class="text-muted text-center" colspan="4">
                <i class="fad fa-empty-set mr-1"></i> No supplies were found.
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="$store.state.livemode && !$store.getters.isSubscribed && !suppliesCount" class="border-top position-relative">
          <div class="empty-overlay position-absolute p-5">
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-6">
                <h2><i class="fad fa-cash-register mr-1"></i> Store Your Transactions</h2>
                <p>Supplies make up the VAT reports which are compiled automatically for you when due. Connect Vatstack with your payment provider or use the API.</p>
                <small v-if="!$store.getters.isSubscribed">
                  <p class="mb-4">You need the <strong>Launch</strong> plan or above to store transactions.</p>
                </small>
                <a-button v-if="$store.getters.isSubscribed" class="mr-2" type="primary" size="small" @click="$router.push({ name: 'integrations' })">
                  Add Integration
                </a-button>
                <a-button v-else type="primary" class="mr-2" size="small" @click="$router.push({ name: 'subscription' })">
                  Upgrade
                </a-button>
                <a href="https://vatstack.com/reports">
                  <a-button size="small">
                    Learn More
                  </a-button>
                </a>
              </div>
            </div>
          </div>
          <table class="table mb-0">
            <tbody>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 310B7863-0008</td>
                <td>99.00 USD</td>
                <td>18.51 USD (23%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 64 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 310B7863-0007</td>
                <td>99.00 USD</td>
                <td>18.51 USD (23%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 128 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-exclamation-circle text-warning mr-1"></i> 394A3053-0001</td>
                <td>99.00 USD</td>
                <td>16.50 USD (20%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 256 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 310B7863-0006</td>
                <td>99.00 USD</td>
                <td>18.51 USD (23%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 512 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 492T3291-0001</td>
                <td>99.00 USD</td>
                <td>17.18 USD (21%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 1024 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 103T3891-0001</td>
                <td>99.00 USD</td>
                <td>0.00 USD (0%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 2048 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> 593T6672-0001</td>
                <td>99.00 USD</td>
                <td>0.00 USD (0%)</td>
                <td>{{ convertDateTime(new Date(new Date() - 4096 * 60000)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseSpinner>
      <div class="card-footer">
        <BasePagination
          v-model="filters.page"
          :hasMore="hasMore"
          :pageCount="Math.ceil(suppliesCount / filters.limit)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import SupplyCreate from '@/components/Supply/SupplyCreate'
import SupplyHits from '@/components/Supply/SupplyHits'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    SupplyCreate,
    SupplyHits,
  },

  data() {
    return {
      loading: false,
      visible: false,
      hasMore: false,
      filters: {
        country_code: this.$route.query.country_code || null,
        invoice_number: this.$route.query.invoice_number || null,
        issued_since: this.$route.query.issued_since || null,
        issued_until: this.$route.query.issued_until || null,
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      supplies: [],
      suppliesCount: null,
    }
  },

  mounted() {
    this.listSupplies()
  },

  methods: {
    async listSupplies() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/supplies`, {
          headers: { 'X-API-KEY': this.$store.getters.secretKey },
          params: this.filters,
        })

        this.hasMore = data.has_more
        this.supplies = data.supplies
        this.suppliesCount = data.supplies_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onSubmit() {
      this.$router.push({ name: 'supplies', query: {
        invoice_number: this.filters.invoice_number,
        issued_since: this.filters.issued_since,
        issued_until: this.filters.issued_until,
      }})
    },
    onQueryChange(e) {
      this.filters.invoice_number = e.target.value
    },
    onDateChange(data) {
      this.filters.issued_since = data && data[0] ? data[0].format('YYYY-MM-DD') : null
      this.filters.issued_until = data && data[1] ? data[1].format('YYYY-MM-DD') : null
    },
    convertPrice(price) {
      return (price / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  computed: {
    defaultPickerValues() {
      if (this.filters.issued_since && this.filters.issued_until) {
        return [
          moment(this.filters.issued_since),
          moment(this.filters.issued_until),
        ]
      } else {
        return null
      }
    },
  },

  watch: {
    '$store.state.livemode'() {
      if (this.filters.page !== 1) {
        this.$router.replace({ name: 'supplies' })
      } else {
        this.listSupplies()
      }
    },
    'filters.page'(value) {
      this.$router.push({ name: 'supplies', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>

<style scoped>
.empty-overlay {
  animation: overlay 1s ease-in 1s forwards;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(132deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
}

@keyframes overlay {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
