var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_c('a-modal',{attrs:{"confirmLoading":_vm.loading,"okText":"Save","title":"New Supply","visible":_vm.visible},on:{"cancel":function($event){return _vm.$emit('close')},"ok":_vm.onConfirm}},[_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onConfirm.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Place of Supply","colon":false,"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country_code', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Place of supply is required.' }],
            }]
          }]),expression:"['country_code', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Place of supply is required.' }],\n            }]\n          }]"}],attrs:{"name":"country_code","placeholder":"Select a country","showSearch":"","filterOption":_vm.filterOption},on:{"change":_vm.onCountryChange}},_vm._l((_vm.$store.state.countries),function(name,code){return _c('a-select-option',{key:code,attrs:{"value":code}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Customer Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name']),expression:"['name']"}],attrs:{"name":"name","type":"text"}})],1),_c('a-form-item',{attrs:{"label":"Item Description","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description']),expression:"['description']"}],attrs:{"name":"description","type":"text"}})],1),_c('h2',{staticClass:"h4"},[_vm._v("Pricing")]),_c('a-form-item',{attrs:{"label":"Amount","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amount', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Amount is required.' }],
            }]
          }]),expression:"['amount', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Amount is required.' }],\n            }]\n          }]"}],attrs:{"name":"amount","placeholder":"0","type":"number"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['currency']),expression:"['currency']"}],staticStyle:{"width":"150px"},attrs:{"slot":"addonAfter","filterOption":_vm.filterOption,"name":"currency","showSearch":""},slot:"addonAfter"},_vm._l((_vm.currencies),function(name){return _c('a-select-option',{key:name,attrs:{"value":name}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"VAT Rate (%)","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vat.rate']),expression:"['vat.rate']"}],attrs:{"disabled":!_vm.rate,"name":"vat.rate"}},[_c('a-select-opt-group',{attrs:{"label":"Standard Rate"}},[_c('a-select-option',{attrs:{"value":_vm.rate && _vm.rate.standard_rate}},[_vm._v(" "+_vm._s(_vm.rate && _vm.rate.standard_rate)+"% ")])],1),(_vm.rate && _vm.rate.reduced_rates.length)?_c('a-select-opt-group',{attrs:{"label":"Reduced Rates"}},_vm._l((_vm.rate.reduced_rates),function(reducedRate){return _c('a-select-option',{key:reducedRate,attrs:{"value":reducedRate}},[_vm._v(" "+_vm._s(reducedRate)+"% ")])}),1):_vm._e()],1)],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"VAT Pricing","colon":false}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vat.inclusive', {
                initialValue: false,
              }]),expression:"['vat.inclusive', {\n                initialValue: false,\n              }]"}],attrs:{"name":"vat.inclusive"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" incl. ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" excl. ")])],1)],1)],1)]),_c('h2',{staticClass:"h4"},[_vm._v("Invoice")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Invoice Number","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['invoice_number', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Invoice number is required.' }],
                }]
              }]),expression:"['invoice_number', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Invoice number is required.' }],\n                }]\n              }]"}],attrs:{"name":"invoice_number","type":"text"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Issued Timestamp","colon":false,"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['issued', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Issued date is required.' }],
                }]
              }]),expression:"['issued', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Issued date is required.' }],\n                }]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Select date and time","show-time":""}})],1)],1)]),_c('a-collapse',{staticStyle:{"background-color":"transparent"},attrs:{"bordered":false}},[_c('a-collapse-panel',{key:"1",staticStyle:{"border-bottom":"none"},attrs:{"header":"More Details"}},[_c('a-form-item',{attrs:{"label":"Notes","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['notes']),expression:"['notes']"}],attrs:{"name":"notes","type":"text"}})],1),_c('a-form-item',{attrs:{"label":"Amount Refunded","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amount_refunded']),expression:"['amount_refunded']"}],attrs:{"name":"amount_refunded","placeholder":"0","type":"number"}})],1)],1)],1)],1),_c('small',[_c('p',[_vm._v("Each new supply will consume "),_c('strong',[_vm._v("1")]),_vm._v(" hit. You can edit a supply afterwards as many times as you want.")])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }