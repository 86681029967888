<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else class="card mb-3">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'supplies' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Supplies</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ supply && supply.invoice_number }}
          </li>
        </ul>
      </div>
      <table class="table mb-0">
        <thead>
          <tr class="thead-light">
            <th class="w-25">Key</th>
            <th class="w-75">Value</th>
          </tr>
          <tr>
            <td class="text-muted align-top">Charge</td>
            <td>
              <div class="row">
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">Total Amount</label>
                  <div>
                    {{ convertPrice(supply.amount_total) }} {{ supply.currency }}
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">VAT Rate</label>
                  <div>
                    {{ supply.vat.rate}}%
                    <span v-if="supply.vat.rate_type === 'standard'" class="badge badge-light ml-2">{{ supply.vat.rate_type }}</span>
                    <span v-else-if="supply.vat.rate_type === 'reduced'" class="badge badge-light ml-2">{{ supply.vat.rate_type }}</span>
                    <span v-else class="badge badge-light ml-2">{{ supply.vat.rate_type }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                  <label class="label">VAT Amount</label>
                  <div>
                    {{ convertPrice(supply.vat.amount) }} {{ supply.currency }} ({{ supply.vat.inclusive ? 'inclusive' : 'exclusive' }})
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Name</td>
            <td>
              <div v-if="supply.name">{{ supply.name }}</div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Place of Supply</td>
            <td>
              <strong>{{ $store.state.countries[supply.country_code] }} ({{ supply.country_code }})</strong>
              <span v-if="supply.evidence_status === 'sufficient'" class="badge badge-success ml-2">{{ supply.evidence_status }} evidence</span>
              <span v-else-if="supply.evidence_status === 'insufficient'" class="badge badge-warning ml-2">{{ supply.evidence_status }} evidence</span>
            </td>
          </tr>
          <tr>
            <td class="text-muted align-top"><i class="fal fa-paperclip mr-1"></i> Evidence</td>
            <td>
              <div v-if="supply.evidence">
                <div class="row">
                  <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                    <label class="label">Billing Address</label>
                    <div v-if="supply.evidence.billing_address.country_code">
                      <div v-if="supply.evidence.billing_address.line_1">{{ supply.evidence.billing_address.line_1 }}</div>
                      <div v-if="supply.evidence.billing_address.line_2">{{ supply.evidence.billing_address.line_2 }}</div>
                      <div>{{ supply.evidence.billing_address.city }} {{ supply.evidence.billing_address.state }} {{ supply.evidence.billing_address.postal_code }}</div>
                      <div><strong>{{ $store.state.countries[supply.evidence.billing_address.country_code] }} ({{ supply.evidence.billing_address.country_code }})</strong></div>
                    </div>
                    <div v-else class="text-muted"><small>Not available</small></div>
                  </div>
                  <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                    <label class="label">IP Address</label>
                    <div v-if="supply.evidence.ip_address.country_code">
                      <div v-if="supply.evidence.ip_address.label">{{ supply.evidence.ip_address.label }} <small>({{ supply.evidence.ip_address.provider }})</small></div>
                      <div>{{ supply.evidence.ip_address.city }}, {{ supply.evidence.ip_address.state }}</div>
                      <div><strong>{{ $store.state.countries[supply.evidence.ip_address.country_code] }} ({{ supply.evidence.ip_address.country_code }})</strong></div>
                    </div>
                    <div v-else class="text-muted"><small>Not available</small></div>
                  </div>
                  <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                    <label class="label">Bank Address</label>
                    <div v-if="supply.evidence.bank_address.country_code">
                      {{ supply.evidence.bank_address.name }} {{ supply.evidence.bank_address.type }}
                      <div><strong>{{ $store.state.countries[supply.evidence.bank_address.country_code] }} ({{ supply.evidence.bank_address.country_code }})</strong></div>
                    </div>
                    <div v-else class="text-muted"><small>Not available</small></div>
                  </div>
                </div>
              </div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted"><i class="fal fa-paperclip mr-1"></i> Validation</td>
            <td>
              <div v-if="supply.validation">
                <router-link :to="{ name: 'validations-id', params: { id: supply.validation.id }}" class="color-inherit">
                  <a-tooltip v-if="supply.validation.valid === true" title="Valid" placement="left">
                    <i class="fad fa-check-circle text-success mr-1"></i> {{ supply.validation.query }}
                  </a-tooltip>
                  <a-tooltip v-else-if="supply.validation.valid === false" title="Invalid" placement="left">
                    <i class="fad fa-times-circle text-danger mr-1"></i> {{ supply.validation.query }}
                  </a-tooltip>
                  <a-tooltip v-else :title="$store.state.errorMessages[supply.validation.code]" placement="left">
                    <i class="fad fa-clock text-muted mr-1"></i> {{ supply.validation.query }}
                  </a-tooltip>
                </router-link>
              </div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Description</td>
            <td>{{ supply.description }} </td>
          </tr>
          <tr>
            <td class="text-muted">Notes</td>
            <td>{{ supply.notes }} </td>
          </tr>
          <tr>
            <td class="text-muted">Issued</td>
            <td>{{ convertDateTime(supply.issued) }}</td>
          </tr>
          <tr>
            <td class="text-muted">Created</td>
            <td>{{ convertDateTime(supply.created) }}</td>
          </tr>
        </thead>
      </table>
      <div class="card-footer text-muted">
        <small>Last updated {{ convertDateTime(supply.updated) }}</small>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Raw JSON</strong>
      </div>
      <pre class="rounded-bottom p-4"><code>{{ supply }}</code></pre>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      supply: null,
    }
  },

  async mounted() {
    try {
      var { data } = await this.$http.get(`/supplies/${ this.$route.params.id }`, {
        headers: { 'x-api-key': this.$store.getters.secretKey },
      })

      this.loading = false
      this.supply = data
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          this.$router.push({ name: '404' })
        }

        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }

      return false
    }
  },

  methods: {
    convertPrice(price) {
      return (price / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'supplies' })
    },
  },
}
</script>
